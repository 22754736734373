import React, { useEffect, useState } from 'react';
import './IncidenceColor.css'

function IncidenceColor(props) {

    let typeClass
    const numberOptions = {minimumFractionDigits: 0, maximumFractionDigits: 0};
    const numberFormat = new Intl.NumberFormat(navigator.language, numberOptions);

    if ( props.value >= 100 ) {
        typeClass = 'itemd-balik-1'
    } else if ( props.value >= 75 ) {
        typeClass = 'itemd-balik-3'
    } else if ( props.value >= 50 ) {
        typeClass = 'itemd-balik-4'
    } else if ( props.value >= 25 ) {
        typeClass = 'itemd-balik-5'
    } else if ( props.value > 0 ) {
        typeClass = 'itemd-balik-6'
    } else {
        typeClass = ''
    }

    return (
        <div className={typeClass}>{numberFormat.format(props.value)}</div>
    );

}

export default IncidenceColor;