import {useEffect, useState} from "react"
import api from "../api"

export default function useObec(id) {

  const [obec, setObec] = useState([])

  let params = ""

  if ( id ) {
    params = `?id=${id}`
  }

  useEffect(() => {
    api.get(`/obec${params}`)
      .then(({data}) => setObec(data))
  }, [])

  return obec
}
