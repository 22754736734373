import React, { useEffect, useState } from 'react';

function ColorValue(props) {

    let withoutZero = true
    if ( props.withoutZero ) {
        withoutZero = props.withoutZero ? true : false
    }

    let invert = false
    if ( props.invert ) {
        invert = props.invert ? true : false
    }


    let out = ''
    if ( props.value > 0 ) {

        const numberOptions = {minimumFractionDigits: 0, maximumFractionDigits: 0};
        const numberFormat = new Intl.NumberFormat(navigator.language, numberOptions);

        if (invert) {
            out = <span className="item-green">+{numberFormat.format(props.value)}</span>
        } else {
            out = <span className="item-red">+{numberFormat.format(props.value)}</span>
        }
    } else if ( props.value < 0 ) {

        const numberOptions = {minimumFractionDigits: 0, maximumFractionDigits: 0};
        const numberFormat = new Intl.NumberFormat(navigator.language, numberOptions);

        if (invert) {
            out = <span className="item-red">{numberFormat.format(props.value)}</span>
        } else {
            out = <span className="item-green">{numberFormat.format(props.value)}</span>
        }

    } else {
        if ( withoutZero ) {
            out = <span></span>;
        } else {
            out = <span>0</span>;
        }

    }

    return (
        <span className="ColorValue">{out}</span>
    );

}

export default ColorValue;