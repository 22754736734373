import React, { useEffect, useState } from 'react';
import './Obec.css';
import useObec from "./hook/useObec"
import Moment from 'moment';
import History from "./components/History";

function Obec(props) {

    const [isOpened, setIsOpened] = useState(true);
    const [isHistoryOpened, setIsHistoryOpened] = useState(false);

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
    }
    function toggleHistory() {
        setIsHistoryOpened(wasOpened => !wasOpened);
    }

    Moment.locale('cs');

    console.log(props)

    const obec = useObec(props.id)
    console.log(obec)

    const numberOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 };
    const numberFormat = new Intl.NumberFormat(navigator.language, numberOptions);

    let denniRozdil;
    let tydenRozdil;
    let denniRozdil65;
    let tydenRozdil65;
    let denniVyleceni;
    let tydenVyleceni;
    let incidence7;
    let incidence7color;

    if ( obec.rozdil ) {
        if ( obec.rozdil['pozitivni'] > 0 ) {
            denniRozdil = <span className="item-red">+{numberFormat.format(obec.rozdil['pozitivni'])}</span>
        } else {
            denniRozdil = <span>0</span>;
        }
        if ( obec.rozdil['pozitivni65'] > 0 ) {
            denniRozdil65 = <span className="item-red">+{numberFormat.format(obec.rozdil['pozitivni65'])}</span>
        } else {
            denniRozdil65 = <span>0</span>
        }
        if ( obec.rozdil['vyleceni+umrti'] > 0 ) {
            denniVyleceni = <span className="item-green">+{numberFormat.format(obec.rozdil['vyleceni+umrti'])}</span>
        } else {
            denniVyleceni = <span>0</span>
        }

    } else {
        denniRozdil = <span>0</span>
        denniRozdil65 = <span>0</span>
        denniVyleceni = <span>0</span>
    }

    if ( obec.tyden ) {
        if ( obec.tyden['pozitivni'] > 0 ) {
            tydenRozdil = <span className="item-red">+{numberFormat.format(obec.tyden['pozitivni'])}</span>
        } else {
            tydenRozdil = <span>0</span>;
        }
        if ( obec.tyden['pozitivni65'] > 0 ) {
            tydenRozdil65 = <span className="item-red">+{numberFormat.format(obec.tyden['pozitivni65'])}</span>
        } else {
            tydenRozdil65 = <span>0</span>;
        }
        if ( obec.tyden['vyleceni+umrti'] > 0 ) {
            tydenVyleceni = <span className="item-green">+{numberFormat.format(obec.tyden['vyleceni+umrti'])}</span>
        } else {
            tydenVyleceni = <span>0</span>
        }

        if ( obec.tyden['pozitivni_100'] >= 100 ) {
            incidence7 = <span className="item-red">{numberFormat.format(obec.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-1'
        } else if ( obec.tyden['pozitivni_100'] >= 75 ) {
            incidence7 = <span className="item-green">{numberFormat.format(obec.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-3'
        } else if ( obec.tyden['pozitivni_100'] >= 50 ) {
            incidence7 = <span className="item-green">{numberFormat.format(obec.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-4'
        } else if ( obec.tyden['pozitivni_100'] >= 25 ) {
            incidence7 = <span className="item-green">{numberFormat.format(obec.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-5'
        } else if ( obec.tyden['pozitivni_100'] > 0 ) {
            incidence7 = <span className="item-green">{numberFormat.format(obec.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-6'
        } else {
            incidence7 = <span>0</span>
            incidence7color = 'item-value item-balik'
        }

    } else {
        tydenRozdil = <span>0</span>
        tydenRozdil65 = <span>0</span>
        tydenVyleceni = <span>0</span>
        incidence7 = <span>0</span>
        incidence7color = 'item-value item-balik item-balik-6'
    }


    return (
        <div className="Obec">
            <div className="block">
                <div className="block-header" onClick={toggle}>
                    <h2>{props.name}</h2>
                    <div className="block-header-info">
                        { !isOpened && (
                            <div className="header-item-container">
                                <div className="header-item">
                                    <div className="header-item-label">Denní případy</div>
                                    <div className="header-item-value">{denniRozdil}</div>
                                </div>
                                <div className="header-item">
                                    <div className="header-item-label">Aktivní případy</div>
                                    <div className="header-item-value">{numberFormat.format(obec.aktivni)}</div>
                                </div>
                                <div className="header-item">
                                    <div className="header-item-label">Aktivní případy (100)</div>
                                    <div className="header-item-value">{obec.aktivni_100 ? numberFormat.format(obec.aktivni_100) : 0}</div>
                                </div>
                                <div className="header-item">
                                    <div className="header-item-label">incidence7 (100)</div>
                                    <div className="header-item-value">
                                        {incidence7}
                                        &nbsp;&nbsp;
                                        {obec.rozdil && obec.rozdil['incidence_100'] > 0 && (<strong className="item-red">↗</strong>)}
                                        {obec.rozdil && obec.rozdil['incidence_100'] < 0 && (<strong className="item-green">↘</strong>)}
                                    </div>
                                </div>


                            </div>
                        )}
                        { isOpened && (
                            <div className="header-item-container">
                                <div className="header-item">
                                    <div className="header-item-label">Počet obyvatel:</div>
                                    <div className="header-item-value">{numberFormat.format(obec.pocet_obyvatel)}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isOpened && (
                <div className="block-info">
                    <div className="info">
                        <div className="info-block">
                        <div className="item">
                            <div className="item-label">Ke dni:</div>
                            <div className="item-value">{Moment(obec.datum).format('D.M.Y')  }</div>
                        </div>
                        <div className="item">
                            <div className="item-label">Aktivní případy (na 100 tisíc):</div>
                            <div className="item-value item-big">
                                <small>({obec.aktivni_100 ? numberFormat.format(obec.aktivni_100) : 0})</small> {obec.aktivni}
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-label">Denní rozdíl:</div>
                            <div className="item-subvalue">{denniRozdil}</div>
                        </div>
                        <div className="item">
                            <div className="item-label">Týdenní přírustek:</div>
                            <div className="item-subvalue">{tydenRozdil}</div>
                        </div>
                    </div>
                        <div className="info3-block">

                        <div className="item-inline">
                            <div className="item-label">&nbsp;</div>
                            <div className="item-value-label">Počet</div>
                            <div className="item-value-label">Počet/100tis</div>
                            <div className="item-value-label">Denní rozdíl</div>
                            <div className="item-value-label">Týdenní přírustek</div>
                        </div>

                        <div className="item-inline">
                            <div className="item-label">Potvrzené případy:</div>
                            <div className="item-value-small"><strong>{numberFormat.format(obec.pozitivni)}</strong></div>
                            <div className="item-value-small">{obec.pozitivni_100 ? numberFormat.format(obec.pozitivni_100) : 0}</div>
                            <div className="item-value-small">{denniRozdil}</div>
                            <div className="item-value-small">{tydenRozdil}</div>
                        </div>
                        <div className="item-inline">
                            <div className="item-label">Potvrzené (65+):</div>
                            <div className="item-value-small"><strong>{numberFormat.format(obec.pozitivni65)}</strong></div>
                            <div className="item-value-small"></div>
                            <div className="item-value-small">{denniRozdil65}</div>
                            <div className="item-value-small">{tydenRozdil65}</div>
                        </div>

                        <div className="item-inline">
                            <div className="item-label">Vyléčení + Úmrtí:</div>
                            <div className="item-value-small"><strong>{obec.vyleceni_umrti}</strong></div>
                            <div className="item-value-small">{obec.vyleceni_umrti_100 ? numberFormat.format(obec.vyleceni_umrti_100) : 0}</div>
                            <div className="item-value-small">{denniVyleceni}</div>
                            <div className="item-value-small">{tydenVyleceni}</div>
                        </div>

                        <div className="item-inline">
                            <div className="item-label">
                                &nbsp;<br />&nbsp;<br />
                                * Zdroj neobsahuje přímou hodnotu. Jde o rozdíl mezi celkovým počtem potvrzených případů a aktivních případů. Výsledná hodnota ve většině případů představuje jak vyléčené, tak i úmrtí.</div>
                        </div>

                    </div>
                        <div className="info-block-last">
                        <div className="item">
                            <div className="item-label">R:</div>
                            <div className="item-value item-big-center">{obec.R ? obec.R.toFixed(4) : 0}</div>
                        </div>
                        <div className="item">
                            <div className="item-label">Nové případy za týden (na 100 tisíc):</div>
                            <div className="item-value item-big-center">
                                {incidence7}
                                &nbsp;&nbsp;
                                {obec.rozdil && obec.rozdil['incidence_100'] > 0 && (<strong className="item-red">↗</strong>)}
                                {obec.rozdil && obec.rozdil['incidence_100'] < 0 && (<strong className="item-green">↘</strong>)}
                            </div>
                            <div className={incidence7color}></div>
                        </div>
                        <br />
                        <div className="item">
                            <div className="item-label">První případ:</div>
                            <div className="item-value">{Moment(obec.datum_prvni).format('D.M.Y')  }</div>
                        </div>
                    </div>
                    </div>
                    <div className="action">
                        <div className="action-item" onClick={toggleHistory} >Historie</div>
                        <div className="action-item" >Grafy</div>
                        <div className="action-item" >Sousední obce</div>
                    </div>
                    {isHistoryOpened && ( <History historie={obec.historie} hasPozitivni65='1' vyleceniUmrti='1' /> )}
                </div>
                )}
            </div>
        </div>
    );
}

export default Obec;