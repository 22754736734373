import axios from "axios"

let url
switch(process.env.NODE_ENV) {
    case 'production':
        url = 'https://covid.tomv.cz/api';
        break;
    case 'development':
    default:
        url = 'http://covid.tomv.local/api';
}

const api = axios.create({
  baseURL: url
})

api.interceptors.response.use((response) => {
  //handleError(response)
  return response
}, (error) => {
  handleError(error.response)
  return Promise.reject(error)
})

const handleError = (response) => {

    alert(response);
}

export default api
