import {useEffect, useState} from "react"
import api from "../api"

export default function useOkres(id) {

  const [okres, setOkres] = useState([])

  let params = ""

  if ( id ) {
    params = `?id=${id}`
  }

  useEffect(() => {
    api.get(`/okres${params}`)
        .then(({data}) => setOkres(data))
  }, [])

  return okres
}
