import {useEffect, useState} from "react"
import api from "../api"

export default function useKraj(id) {

  const [kraj, setKraj] = useState([])

  let params = ""

  if ( id ) {
    params = `?id=${id}`
  }

  useEffect(() => {
    api.get(`/kraj${params}`)
        .then(({data}) => setKraj(data))
  }, [])

  return kraj
}
