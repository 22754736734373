import {useEffect, useState} from "react"
import api from "../api"

export default function useMachines() {
  const [cr, setCR] = useState([])

  useEffect(() => {
    api.get("/cr")
      .then(({data}) => setCR(data))
  }, [])

  return cr
}
