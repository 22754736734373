import React, { useEffect, useState } from 'react';
import './Okres.css';
import useOkres from "./hook/useOkres"
import Moment from 'moment';
import History from "./components/History";

function Okres(props) {

    const [isOpened, setIsOpened] = useState(false);
    const [isHistoryOpened, setIsHistoryOpened] = useState(false);

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
    }
    function toggleHistory() {
        setIsHistoryOpened(wasOpened => !wasOpened);
    }

    const okres = useOkres(props.id)
    console.log(okres)

    const numberOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 };
    const numberFormat = new Intl.NumberFormat(navigator.language, numberOptions);

    let denniRozdil;
    let tydenRozdil;
    let denniVyleceni;
    let tydenVyleceni;
    let denniUmrti;
    let tydenUmrti;
    let incidence7;
    let incidence7color;

    if ( okres.rozdil ) {
        if ( okres.rozdil['pozitivni'] > 0 ) {
            denniRozdil = <span className="item-red">+{numberFormat.format(okres.rozdil['pozitivni'])}</span>
        } else {
            denniRozdil = <span>0</span>;
        }
        if ( okres.rozdil['vyleceni'] > 0 ) {
            denniVyleceni = <span className="item-green">+{numberFormat.format(okres.rozdil['vyleceni'])}</span>
        } else {
            denniVyleceni = <span>0</span>
        }
        if ( okres.rozdil['umrti'] > 0 ) {
            denniUmrti = <span className="item-red">+{numberFormat.format(okres.rozdil['umrti'])}</span>
        } else {
            denniUmrti = <span>0</span>
        }

    } else {
        denniRozdil = <span>0</span>
        denniVyleceni = <span>0</span>
        denniUmrti = <span>0</span>
    }

    if ( okres.tyden ) {
        if ( okres.tyden['pozitivni'] > 0 ) {
            tydenRozdil = <span className="item-red">+{numberFormat.format(okres.tyden['pozitivni'])}</span>
        } else {
            tydenRozdil = <span>0</span>;
        }
        if ( okres.tyden['vyleceni'] > 0 ) {
            tydenVyleceni = <span className="item-green">+{numberFormat.format(okres.tyden['vyleceni'])}</span>
        } else {
            tydenVyleceni = <span>0</span>
        }
        if ( okres.tyden['umrti'] > 0 ) {
            tydenUmrti = <span className="item-red">+{numberFormat.format(okres.tyden['umrti'])}</span>
        } else {
            tydenUmrti = <span>0</span>
        }

        if ( okres.tyden['pozitivni_100'] >= 100 ) {
            incidence7 = <span className="item-red">{numberFormat.format(okres.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-1'
        } else if ( okres.tyden['pozitivni_100'] >= 75 ) {
            incidence7 = <span className="item-green">{numberFormat.format(okres.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-3'
        } else if ( okres.tyden['pozitivni_100'] >= 50 ) {
            incidence7 = <span className="item-green">{numberFormat.format(okres.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-4'
        } else if ( okres.tyden['pozitivni_100'] >= 25 ) {
            incidence7 = <span className="item-green">{numberFormat.format(okres.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-5'
        } else if ( okres.tyden['pozitivni_100'] > 0 ) {
            incidence7 = <span className="item-green">{numberFormat.format(okres.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-6'
        } else {
            incidence7 = <span>0</span>
            incidence7color = 'item-value item-balik'
        }

    } else {
        tydenRozdil = <span>0</span>
        tydenVyleceni = <span>0</span>
        tydenUmrti = <span>0</span>
        incidence7 = <span>0</span>
        incidence7color = 'item-value item-balik item-balik-6'
    }


    return (
        <div className="Okres">
            <div className="block">
                <div className="block-header" onClick={toggle}>
                    <h2>Okres {props.name}</h2>
                    <div className="block-header-info">
                        { !isOpened && (
                            <div className="header-item-container">
                                <div className="header-item">
                                    <div className="header-item-label">Denní případy</div>
                                    <div className="header-item-value">{denniRozdil}</div>
                                </div>
                                <div className="header-item">
                                    <div className="header-item-label">Aktivní případy</div>
                                    <div className="header-item-value">{numberFormat.format(okres.aktivni)}</div>
                                </div>
                                <div className="header-item">
                                    <div className="header-item-label">Aktivní případy (100)</div>
                                    <div className="header-item-value">{okres.aktivni_100 ? numberFormat.format(okres.aktivni_100) : 0}</div>
                                </div>
                                <div className="header-item">
                                    <div className="header-item-label">incidence7 (100)</div>
                                    <div className="header-item-value">{incidence7}</div>
                                </div>


                            </div>
                        )}
                        { isOpened && (
                            <div className="header-item-container">
                                <div className="header-item">
                                    <div className="header-item-label">Počet obyvatel:</div>
                                    <div className="header-item-value">{numberFormat.format(okres.pocet_obyvatel)}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isOpened && (
                <div className="block-info">
                    <div className="info">
                        <div className="info-block">
                            <div className="item">
                                <div className="item-label">Ke dni:</div>
                                <div className="item-value">{Moment(okres.datum).format('D.M.Y')}</div>
                            </div>
                            <div className="item">
                                <div className="item-label">Aktivní případy (na 100 tisíc):</div>
                                <div className="item-value item-big">
                                    <small>({okres.aktivni_100 ? numberFormat.format(okres.aktivni_100) : 0})</small> {numberFormat.format(okres.aktivni)}
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-label">Denní rozdíl:</div>
                                <div className="item-subvalue">{denniRozdil}</div>
                            </div>
                            <div className="item">
                                <div className="item-label">Týdenní přírustek:</div>
                                <div className="item-subvalue">{tydenRozdil}</div>
                            </div>
                        </div>
                        <div className="info3-block">

                            <div className="item-inline">
                                <div className="item-label">&nbsp;</div>
                                <div className="item-value-label">Počet</div>
                                <div className="item-value-label">Počet/100tis</div>
                                <div className="item-value-label">Denní rozdíl</div>
                                <div className="item-value-label">Týdenní přírustek</div>
                            </div>

                            <div className="item-inline">
                                <div className="item-label">Potvrzené případy:</div>
                                <div className="item-value-small"><strong>{numberFormat.format(okres.pozitivni)}</strong></div>
                                <div
                                    className="item-value-small">{okres.pozitivni_100 ? numberFormat.format(okres.pozitivni_100) : 0}</div>
                                <div className="item-value-small">{denniRozdil}</div>
                                <div className="item-value-small">{tydenRozdil}</div>
                            </div>
                            <div className="item-inline">
                                <div className="item-label">Vyléčení:</div>
                                <div className="item-value-small"><strong>{numberFormat.format(okres.vyleceni)}</strong></div>
                                <div
                                    className="item-value-small">{okres.vyleceni_100 ? numberFormat.format(okres.vyleceni_100) : 0}</div>
                                <div className="item-value-small">{denniVyleceni}</div>
                                <div className="item-value-small">{tydenVyleceni}</div>
                            </div>
                            <div className="item-inline">
                                <div className="item-label">Úmrtí:</div>
                                <div className="item-value-small"><strong>{numberFormat.format(okres.umrti)}</strong></div>
                                <div
                                    className="item-value-small">{okres.umrti_100 ? numberFormat.format(okres.umrti_100) : 0}</div>
                                <div className="item-value-small">{denniUmrti}</div>
                                <div className="item-value-small">{tydenUmrti}</div>
                            </div>

                        </div>
                        <div className="info-block-last">
                            <div className="item">
                                <div className="item-label">R:</div>
                                <div className="item-value item-big-center">{okres.R ? okres.R.toFixed(4) : 0}</div>
                            </div>
                            <div className="item">
                                <div className="item-label">Nové případy za týden (na 100 tisíc):</div>
                                <div className="item-value item-big-center">
                                    {incidence7}
                                </div>
                                <div className={incidence7color}></div>
                            </div>
                        </div>
                    </div>
                    <div className="action">
                        <div className="action-item" onClick={toggleHistory} >Historie</div>
                        <div className="action-item" >Grafy</div>
                        <div className="action-item" >Sousední obce</div>
                    </div>
                    {isHistoryOpened && ( <History historie={okres.historie} /> )}
                </div>
                )}
            </div>
        </div>
    );
}

export default Okres;