import React, { useEffect, useState } from 'react';
import './Orp.css';
import useORP from "./hook/useORP"
import Moment from 'moment';
import History from "./components/History";

function Orp(props) {

    const [isOpened, setIsOpened] = useState(false);
    const [isHistoryOpened, setIsHistoryOpened] = useState(false);

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
    }
    function toggleHistory() {
        setIsHistoryOpened(wasOpened => !wasOpened);
    }

    const orp = useORP(props.id)
    console.log(orp)

    const numberOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 };
    const numberFormat = new Intl.NumberFormat(navigator.language, numberOptions);

    let denniRozdil;
    let tydenRozdil;
    let denniRozdil65;
    let tydenRozdil65;
    let denniVyleceni;
    let tydenVyleceni;
    let incidence7;
    let incidence7color;

    if ( orp.rozdil ) {
        if ( orp.rozdil['pozitivni'] > 0 ) {
            denniRozdil = <span className="item-red">+{numberFormat.format(orp.rozdil['pozitivni'])}</span>
        } else {
            denniRozdil = <span>0</span>;
        }
        if ( orp.rozdil['pozitivni65'] > 0 ) {
            denniRozdil65 = <span className="item-red">+{numberFormat.format(orp.rozdil['pozitivni65'])}</span>
        } else {
            denniRozdil65 = <span>0</span>
        }
        if ( orp.rozdil['vyleceni+umrti'] > 0 ) {
            denniVyleceni = <span className="item-green">+{numberFormat.format(orp.rozdil['vyleceni+umrti'])}</span>
        } else {
            denniVyleceni = <span>0</span>
        }

    } else {
        denniRozdil = <span>0</span>
        denniRozdil65 = <span>0</span>
        denniVyleceni = <span>0</span>
    }

    if ( orp.tyden ) {
        if ( orp.tyden['pozitivni'] > 0 ) {
            tydenRozdil = <span className="item-red">+{numberFormat.format(orp.tyden['pozitivni'])}</span>
        } else {
            tydenRozdil = <span>0</span>;
        }
        if ( orp.tyden['pozitivni65'] > 0 ) {
            tydenRozdil65 = <span className="item-red">+{numberFormat.format(orp.tyden['pozitivni65'])}</span>
        } else {
            tydenRozdil65 = <span>0</span>;
        }
        if ( orp.tyden['vyleceni+umrti'] > 0 ) {
            tydenVyleceni = <span className="item-green">+{numberFormat.format(orp.tyden['vyleceni+umrti'])}</span>
        } else {
            tydenVyleceni = <span>0</span>
        }

        if ( orp.tyden['pozitivni_100'] >= 100 ) {
            incidence7 = <span className="item-red">{numberFormat.format(orp.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-1'
        } else if ( orp.tyden['pozitivni_100'] >= 75 ) {
            incidence7 = <span className="item-green">{numberFormat.format(orp.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-3'
        } else if ( orp.tyden['pozitivni_100'] >= 50 ) {
            incidence7 = <span className="item-green">{numberFormat.format(orp.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-4'
        } else if ( orp.tyden['pozitivni_100'] >= 25 ) {
            incidence7 = <span className="item-green">{numberFormat.format(orp.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-5'
        } else if ( orp.tyden['pozitivni_100'] > 0 ) {
            incidence7 = <span className="item-green">{numberFormat.format(orp.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-6'
        } else {
            incidence7 = <span>0</span>
            incidence7color = 'item-value item-balik'
        }

    } else {
        tydenRozdil = <span>0</span>
        tydenRozdil65 = <span>0</span>
        tydenVyleceni = <span>0</span>
        incidence7 = <span>0</span>
        incidence7color = 'item-value item-balik item-balik-6'
    }



    return (
        <div className="Orp">
            <div className="block">
                <div className="block-header" onClick={toggle}>
                    <h2>ORP {orp.nazev}</h2>
                    <div className="block-header-info">
                        { !isOpened && (
                            <div className="header-item-container">
                                <div className="header-item">
                                    <div className="header-item-label">Denní případy</div>
                                    <div className="header-item-value">{denniRozdil}</div>
                                </div>
                                <div className="header-item">
                                    <div className="header-item-label">Aktivní případy</div>
                                    <div className="header-item-value">{numberFormat.format(orp.aktivni)}</div>
                                </div>
                                <div className="header-item">
                                    <div className="header-item-label">Aktivní případy (100)</div>
                                    <div className="header-item-value">{orp.aktivni_100 ? numberFormat.format(orp.aktivni_100) : 0}</div>
                                </div>
                                <div className="header-item">
                                    <div className="header-item-label">incidence7 (100)</div>
                                    <div className="header-item-value">{incidence7}</div>
                                </div>


                            </div>
                        )}
                        { isOpened && (
                            <div className="header-item-container">
                                <div className="header-item">
                                    <div className="header-item-label">Počet obyvatel:</div>
                                    <div className="header-item-value">{numberFormat.format(orp.pocet_obyvatel)}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isOpened && (
                <div className="block-info">
                    <div className="info">
                        <div className="info-block">
                            <div className="item">
                                <div className="item-label">Ke dni:</div>
                                <div className="item-value">{Moment(orp.datum).format('D.M.Y')}</div>
                            </div>
                            <div className="item">
                                <div className="item-label">Aktivní případy (na 100 tisíc):</div>
                                <div className="item-value item-big">
                                    <small>({orp.aktivni_100 ? numberFormat.format(orp.aktivni_100) : 0})</small> {numberFormat.format(orp.aktivni)}
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-label">Denní rozdíl:</div>
                                <div className="item-subvalue">{denniRozdil}</div>
                            </div>
                            <div className="item">
                                <div className="item-label">Týdenní přírustek:</div>
                                <div className="item-subvalue">{tydenRozdil}</div>
                            </div>
                        </div>
                        <div className="info3-block">

                            <div className="item-inline">
                                <div className="item-label">&nbsp;</div>
                                <div className="item-value-label">Počet</div>
                                <div className="item-value-label">Počet/100tis</div>
                                <div className="item-value-label">Denní rozdíl</div>
                                <div className="item-value-label">Týdenní přírustek</div>
                            </div>

                            <div className="item-inline">
                                <div className="item-label">Potvrzené případy:</div>
                                <div className="item-value-small"><strong>{numberFormat.format(orp.pozitivni)}</strong></div>
                                <div className="item-value-small">{orp.pozitivni_100 ? numberFormat.format(orp.pozitivni_100) : 0}</div>
                                <div className="item-value-small">{denniRozdil}</div>
                                <div className="item-value-small">{tydenRozdil}</div>
                            </div>
                            <div className="item-inline">
                                <div className="item-label">Potvrzené (65+):</div>
                                <div className="item-value-small"><strong>{numberFormat.format(orp.pozitivni65)}</strong></div>
                                <div className="item-value-small"></div>
                                <div className="item-value-small">{denniRozdil65}</div>
                                <div className="item-value-small">{tydenRozdil65}</div>
                            </div>

                            <div className="item-inline">
                                <div className="item-label">Vyléčení + Úmrtí:</div>
                                <div className="item-value-small"><strong>{orp.vyleceni_umrti}</strong></div>
                                <div className="item-value-small">{orp.vyleceni_umrti_100 ? numberFormat.format(orp.vyleceni_umrti_100) : 0}</div>
                                <div className="item-value-small">{denniVyleceni}</div>
                                <div className="item-value-small">{tydenVyleceni}</div>
                            </div>

                            <div className="item-inline">
                                <div className="item-label">
                                    &nbsp;<br />&nbsp;<br />
                                    * Zdroj neobsahuje přímou hodnotu. Jde o rozdíl mezi celkovým počtem potvrzených případů a aktivních případů. Výsledná hodnota ve většině případů představuje jak vyléčené, tak i úmrtí.</div>
                            </div>

                        </div>
                        <div className="info-block-last">
                            <div className="item">
                                <div className="item-label">R:</div>
                                <div className="item-value item-big-center">{orp.R ? orp.R.toFixed(4) : 0}</div>
                            </div>
                            <div className="item">
                                <div className="item-label">Nové případy za týden (na 100 tisíc):</div>
                                <div className="item-value item-big-center">{incidence7}</div>
                                <div className={incidence7color}></div>
                            </div>
                        </div>
                    </div>
                    <div className="action">
                        <div className="action-item" onClick={toggleHistory} >Historie</div>
                        <div className="action-item" >Grafy</div>
                        <div className="action-item" >Obce</div>
                    </div>
                    {isHistoryOpened && ( <History historie={orp.historie} hasPozitivni65='1' vyleceniUmrti='1' /> )}

                </div>
                )}
            </div>
        </div>
    );
}

export default Orp;