import React, { useEffect, useState } from 'react';
import './History.css';
import Moment from 'moment';
import 'moment/locale/cs'
import ColorValue from "./ColorValue";
import IncidenceColor from "./IncidenceColor";


function History(props) {

    const [isOpened, setIsOpened] = useState(false);

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
    }
    function toggle30() {
        setIsOpened(false);
    }
    function toggleAll() {
        setIsOpened(true);
    }


    Moment.locale('cs');

    console.log(props)

    const numberOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 };
    const numberFormat = new Intl.NumberFormat(navigator.language, numberOptions);

    let hasPozitivni65
    if ( props.hasPozitivni65 ) {
        hasPozitivni65 = props.hasPozitivni65 ? true : false
    }

    let vyleceniUmrti
    if ( props.vyleceniUmrti ) {
        vyleceniUmrti = props.vyleceniUmrti ? true : false
    }



    return (
        <div className="History">

            <span className="block-in-btn" onClick={toggle30}>za poslední 30 dní</span>
            <span className="block-in-btn" onClick={toggleAll}>vše</span>

            <div className="data">
                <div className="data-header">
                    <div className="data-col-date">Datum</div>

                    <div className="data-col">Aktivní</div>
                    <div className="data-colb">na100tis</div>

                    <div className="data-col">Pozitivní</div>
                    <div className="data-col">denní rozdíl</div>
                    <div className="data-colb">oproti min.týdnu</div>

                    {hasPozitivni65 && (<div className="data-col">Pozitivní 65+</div>)}
                    {hasPozitivni65 && (<div className="data-colb">denní rozdíl</div>)}

                    {vyleceniUmrti && (<div className="data-col">Vyléčení+Úmrtí</div>)}
                    {vyleceniUmrti && (<div className="data-colb">denní rozdíl</div>)}
                    {!vyleceniUmrti && (<div className="data-col">Vyléčení</div>)}
                    {!vyleceniUmrti && (<div className="data-colb">denní rozdíl</div>)}
                    {!vyleceniUmrti && (<div className="data-col">Úmrtí</div>)}
                    {!vyleceniUmrti && (<div className="data-colb">denní rozdíl</div>)}



                    <div className="data-col">Nové za týden (na&nbsp;100t)</div>
                    <div className="data-col">&nbsp;</div>
                    <div className="data-col">R</div>
                </div>

                {Object.keys(props.historie).map((key, index)=>(
                <div className={index > 30 ? 'data-items data-items30' : 'data-items'}>
                    <div className="data-col-date">{Moment(key).format('ddd D.M.Y')  }</div>

                    <div className="data-col"><strong>{numberFormat.format(props.historie[key].aktivni)}</strong></div>
                    <div className="data-colb">{numberFormat.format(props.historie[key].aktivni_100)}</div>

                    <div className="data-col">{numberFormat.format(props.historie[key].pozitivni)}</div>
                    <div className="data-col"><ColorValue value={props.historie[key].rozdil ? props.historie[key].rozdil.pozitivni : ''} withoutZero='1'  /></div>
                    <div className="data-colb"><ColorValue value={props.historie[key].rozdil7 ? props.historie[key].rozdil7.pozitivni : ''} withoutZero='1'  /></div>

                    {hasPozitivni65 && (<div className="data-col">{numberFormat.format(props.historie[key].pozitivni65)}</div>)}
                    {hasPozitivni65 && (<div className="data-colb"><ColorValue value={props.historie[key].rozdil ? props.historie[key].rozdil.pozitivni65 : ''} withoutZero='1'  /></div>)}

                    {vyleceniUmrti && (<div className="data-col">{numberFormat.format(props.historie[key].vyleceni_umrti)}</div>)}
                    {vyleceniUmrti && (<div className="data-colb"><ColorValue value={props.historie[key].rozdil ? props.historie[key].rozdil.vyleceni_umrti : ''} withoutZero='1' invert='1'  /></div>)}
                    {!vyleceniUmrti && (<div className="data-col">{numberFormat.format(props.historie[key].vyleceni)}</div>)}
                    {!vyleceniUmrti && (<div className="data-colb"><ColorValue value={props.historie[key].rozdil ? props.historie[key].rozdil.vyleceni : ''} withoutZero='1' invert='1'  /></div>)}
                    {!vyleceniUmrti && (<div className="data-col">{numberFormat.format(props.historie[key].umrti)}</div>)}
                    {!vyleceniUmrti && (<div className="data-colb"><ColorValue value={props.historie[key].rozdil ? props.historie[key].rozdil.umrti : ''} withoutZero='1' /></div>)}

                    <div className="data-col data-col-flat"><IncidenceColor value={props.historie[key].tyden ? props.historie[key].tyden.pozitivni_100 : 0 } /></div>
                    {props.historie[key].rozdil && props.historie[key].rozdil.incidence_100 > 0 && (<div className="data-col"><ColorValue value={props.historie[key].rozdil ? props.historie[key].rozdil.incidence_100 : ''} withoutZero='1'  /> <strong className="item-red">↗</strong></div>)}
                    {props.historie[key].rozdil && props.historie[key].rozdil.incidence_100 < 0 && (<div className="data-col"><ColorValue value={props.historie[key].rozdil ? props.historie[key].rozdil.incidence_100 : ''} withoutZero='1'  /> <strong className="item-green">↘</strong></div>)}
                    {props.historie[key].rozdil && props.historie[key].rozdil.incidence_100 == 0 && (<div className="data-col"></div>)}
                    <div className="data-col">{props.historie[key].R ? props.historie[key].R.toFixed(4) : 0}</div>
                </div>
                ))}
            </div>
        </div>
    );
}

export default History;