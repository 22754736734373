import './App.css';
import api from "./api"
import Cr from './Cr';
import Kraj from './Kraj';
import Okres from './Okres';
import Orp from "./Orp";
import Obec from './Obec';

import obce from './data/pocet_obyvatel_celek.json';

function App() {

    let id = getQueryVariable('id')
    //console.log(id)//123

    let obecID
    let obecName
    let orpID
    let orpName
    let okresID
    let okresName
    let krajID
    let krajName

    if ( !id ) {
        id = 'CZ0'
        switch(process.env.NODE_ENV) {
            case 'production':
                id = 'CZ0'
                break;
            case 'development':
            default:
                id = 542865
        }
    }

    if ( id && obce[id] ) {
        console.log(obce[id]);
        if ( id == 'CZ0' ) {
            obecID = null;
        } else if ( obce[id].orp ) {
            // obec
            obecID = id
            obecName = obce[id].nazev
            orpID = obce[id].orp.kod
            orpName = obce[id].orp.nazev
            okresID = obce[id].okres.kod
            okresName = obce[id].okres.nazev
            krajID = obce[id].kraj.kod
            krajName = obce[id].kraj.nazev
        } else if ( obce[id].okres ) {
            // orp
            orpID = id
            orpName = obce[id].nazev
            okresID = obce[id].okres.kod
            okresName = obce[id].okres.nazev
            krajID = obce[id].kraj.kod
            krajName = obce[id].kraj.nazev
        } else if ( obce[id].kraj ) {
            // okres
            okresID = id
            okresName = obce[id].nazev
            krajID = obce[id].kraj.kod
            krajName = obce[id].kraj.nazev
        } else {
            // kraj
            krajID = id
            krajName = obce[id].nazev
        }
    }

    return (
        <div className="App">
            <header className="App-header">
                <h1>Statistiky onemocnění covid-19</h1>
            </header>

            <Cr />
            { krajID && (<Kraj id={krajID} name={krajName} />)}
            { okresID && (<Okres id={okresID} name={okresName} />)}
            { orpID && (<Orp id={orpID} name={orpName} />)}
            { obecID && (<Obec id={obecID} name={obecName} />)}

        </div>
    );

    function getQueryVariable(variable)
    {
        var query = window.location.search.substring(1);
        //console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
            if(pair[0] == variable){return pair[1];}
        }
        return(false);
    }


}

export default App;
