import React, { useEffect, useState } from 'react';
import './Cr.css';
import useCR from "./hook/useCR"
import useCrHospitalizace from "./hook/useCRHospitalizace";
import Moment from 'moment';
import History from "./components/History";
import HistoryHospitalizace from "./components/HistoryHospitalizace";
import ColorValue from "./components/ColorValue";

function Cr() {

    const [isOpened, setIsOpened] = useState(true);
    const [isHistoryOpened, setIsHistoryOpened] = useState(false);
    const [isHospitalizaceOpened, setIsHospitalizaceOpened] = useState(false);

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
    }
    function toggleHistory() {
        setIsHistoryOpened(wasOpened => !wasOpened);
        if ( isHospitalizaceOpened ) setIsHospitalizaceOpened(wasOpened => !wasOpened);
    }
    function toggleHospitalizace() {
        setIsHospitalizaceOpened(wasOpened => !wasOpened);
        if ( isHistoryOpened ) setIsHistoryOpened(wasOpened => !wasOpened);
    }

    const cr = useCR()
    console.log(cr)

    const crHospitalizace = useCrHospitalizace()

    const numberOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 };
    const numberFormat = new Intl.NumberFormat(navigator.language, numberOptions);

    let denniRozdil;
    let tydenRozdil;
    let denniRozdil65;
    let tydenRozdil65;
    let denniVyleceni;
    let tydenVyleceni;
    let denniUmrti;
    let tydenUmrti;
    let incidence7;
    let incidence7color;

    if ( cr.rozdil ) {
        if ( cr.rozdil['pozitivni'] > 0 ) {
            denniRozdil = <span className="item-red">+{numberFormat.format(cr.rozdil['pozitivni'])}</span>
        } else {
            denniRozdil = <span>0</span>;
        }
        if ( cr.rozdil['pozitivni65'] > 0 ) {
            denniRozdil65 = <span className="item-red">+{numberFormat.format(cr.rozdil['pozitivni65'])}</span>
        } else {
            denniRozdil65 = <span>0</span>
        }
        if ( cr.rozdil['vyleceni'] > 0 ) {
            denniVyleceni = <span className="item-green">+{numberFormat.format(cr.rozdil['vyleceni'])}</span>
        } else {
            denniVyleceni = <span>0</span>
        }
        if ( cr.rozdil['umrti'] > 0 ) {
            denniUmrti = <span className="item-red">+{numberFormat.format(cr.rozdil['umrti'])}</span>
        } else {
            denniUmrti = <span>0</span>
        }

    } else {
        denniRozdil = <span>0</span>
        denniRozdil65 = <span>0</span>
        denniVyleceni = <span>0</span>
        denniUmrti = <span>0</span>
    }

    if ( cr.tyden ) {
        if ( cr.tyden['pozitivni'] > 0 ) {
            tydenRozdil = <span className="item-red">+{numberFormat.format(cr.tyden['pozitivni'])}</span>
        } else {
            tydenRozdil = <span>0</span>;
        }
        if ( cr.tyden['pozitivni65'] > 0 ) {
            tydenRozdil65 = <span className="item-red">+{numberFormat.format(cr.tyden['pozitivni65'])}</span>
        } else {
            tydenRozdil65 = <span>0</span>;
        }
        if ( cr.tyden['vyleceni'] > 0 ) {
            tydenVyleceni = <span className="item-green">+{numberFormat.format(cr.tyden['vyleceni'])}</span>
        } else {
            tydenVyleceni = <span>0</span>
        }
        if ( cr.tyden['umrti'] > 0 ) {
            tydenUmrti = <span className="item-red">+{numberFormat.format(cr.tyden['umrti'])}</span>
        } else {
            tydenUmrti = <span>0</span>
        }

        if ( cr.tyden['pozitivni_100'] >= 100 ) {
            incidence7 = <span className="item-red">{numberFormat.format(cr.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-1'
        } else if ( cr.tyden['pozitivni_100'] >= 75 ) {
            incidence7 = <span className="item-green">{numberFormat.format(cr.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-3'
        } else if ( cr.tyden['pozitivni_100'] >= 50 ) {
            incidence7 = <span className="item-green">{numberFormat.format(cr.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-4'
        } else if ( cr.tyden['pozitivni_100'] >= 25 ) {
            incidence7 = <span className="item-green">{numberFormat.format(cr.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-5'
        } else if ( cr.tyden['pozitivni_100'] > 0 ) {
            incidence7 = <span className="item-green">{numberFormat.format(cr.tyden['pozitivni_100'])}</span>
            incidence7color = 'item-value item-balik item-balik-6'
        } else {
            incidence7 = <span>0</span>
            incidence7color = 'item-value item-balik'
        }

    } else {
        tydenRozdil = <span>0</span>
        tydenRozdil65 = <span>0</span>
        tydenVyleceni = <span>0</span>
        tydenUmrti = <span>0</span>
        incidence7 = <span>0</span>
        incidence7color = 'item-value item-balik item-balik-6'
    }


    return (
        <div className="Cr">
            <div className="block">
                <div className="block-header" onClick={toggle}>
                    <h2>Česká republika</h2>
                    <div className="block-header-info">
                        { !isOpened && (
                            <div className="header-item-container">
                                <div className="header-item">
                                    <div className="header-item-label">Denní případy</div>
                                    <div className="header-item-value">{denniRozdil}</div>
                                </div>
                                <div className="header-item">
                                    <div className="header-item-label">Aktivní případy</div>
                                    <div className="header-item-value">{numberFormat.format(cr.aktivni)}</div>
                                </div>
                                <div className="header-item">
                                    <div className="header-item-label">Aktivní případy (100)</div>
                                    <div className="header-item-value">{cr.aktivni_100 ? numberFormat.format(cr.aktivni_100) : 0}</div>
                                </div>
                                <div className="header-item">
                                    <div className="header-item-label">incidence7 (100)</div>
                                    <div className="header-item-value">
                                        {incidence7} &nbsp;&nbsp;
                                        {cr.rozdil && cr.rozdil['incidence_100'] > 0 && (<strong className="item-red">↗</strong>)}
                                        {cr.rozdil && cr.rozdil['incidence_100'] < 0 && (<strong className="item-green">↘</strong>)}
                                    </div>
                                </div>


                            </div>
                        )}
                        { isOpened && (
                            <div className="header-item-container">
                                <div className="header-item">
                                    <div className="header-item-label">Počet obyvatel:</div>
                                    <div className="header-item-value">{numberFormat.format(cr.pocet_obyvatel)}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isOpened && (
                <div className="block-info">
                    <div className="info">
                        <div className="info-block">
                            <div className="item">
                                <div className="item-label">Ke dni:</div>
                                <div className="item-value">{Moment(cr.datum).format('D.M.Y')}</div>
                            </div>
                            <div className="item">
                                <div className="item-label">Aktivní případy (na 100 tisíc):</div>
                                <div className="item-value item-big">
                                    <small>({cr.aktivni_100 ? numberFormat.format(cr.aktivni_100) : 0})</small> {numberFormat.format(cr.aktivni)}
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-label">Denní rozdíl:</div>
                                <div className="item-subvalue">{denniRozdil}</div>
                            </div>
                            <div className="item">
                                <div className="item-label">Týdenní přírustek:</div>
                                <div className="item-subvalue">{tydenRozdil}</div>
                            </div>
                        </div>
                        <div className="info3-block">

                            <div className="item-inline">
                                <div className="item-label">&nbsp;</div>
                                <div className="item-value-label">Počet</div>
                                <div className="item-value-label">Počet/100tis</div>
                                <div className="item-value-label">Denní rozdíl</div>
                                <div className="item-value-label">Týdenní přírustek</div>
                            </div>

                            <div className="item-inline">
                                <div className="item-label">Potvrzené případy:</div>
                                <div className="item-value-small"><strong>{numberFormat.format(cr.pozitivni)}</strong></div>
                                <div
                                    className="item-value-small">{cr.pozitivni_100 ? numberFormat.format(cr.pozitivni_100) : 0}</div>
                                <div className="item-value-small">{denniRozdil}</div>
                                <div className="item-value-small">{tydenRozdil}</div>
                            </div>
                            <div className="item-inline">
                                <div className="item-label">Potvrzené (65+):</div>
                                <div className="item-value-small"><strong>{numberFormat.format(cr.pozitivni65)}</strong></div>
                                <div className="item-value-small"></div>
                                <div className="item-value-small">{denniRozdil65}</div>
                                <div className="item-value-small"></div>
                            </div>
                            <div className="item-inline">
                                <div className="item-label">Vyléčení:</div>
                                <div className="item-value-small"><strong>{numberFormat.format(cr.vyleceni)}</strong></div>
                                <div
                                    className="item-value-small">{cr.vyleceni_100 ? numberFormat.format(cr.vyleceni_100) : 0}</div>
                                <div className="item-value-small">{denniVyleceni}</div>
                                <div className="item-value-small">{tydenVyleceni}</div>
                            </div>
                            <div className="item-inline item-inline-border">
                                <div className="item-label">Úmrtí:</div>
                                <div className="item-value-small"><strong>{numberFormat.format(cr.umrti)}</strong></div>
                                <div
                                    className="item-value-small">{cr.umrti_100 ? numberFormat.format(cr.umrti_100) : 0}</div>
                                <div className="item-value-small">{denniUmrti}</div>
                                <div className="item-value-small">{tydenUmrti}</div>
                            </div>

                            <div className="item-inline">
                                <div className="item-label">Hospitalizováni:</div>
                                <div className="item-value-label">Celkem</div>
                                <div className="item-value-label">Kritických</div>
                                <div className="item-value-label">Středních</div>
                                <div className="item-value-label">Lehkých</div>
                                <div className="item-value-label">Bez příznaku</div>
                            </div>

                            <div className="item-inline">
                                <div className="item-label">&nbsp;</div>
                                <div className="item-value-small">
                                    {crHospitalizace.pocet}
                                    <br />
                                    <small><ColorValue value={crHospitalizace.rozdil ? crHospitalizace.rozdil.pocet : 0} withoutZero='1'  /></small>
                                </div>
                                <div className="item-value-small">
                                    {crHospitalizace.tezky}
                                    <br />
                                    <small><ColorValue value={crHospitalizace.rozdil ? crHospitalizace.rozdil.tezky : 0} withoutZero='1'  /></small>
                                </div>
                                <div className="item-value-small">
                                    {crHospitalizace.stredni}
                                    <br />
                                    <small><ColorValue value={crHospitalizace.rozdil ? crHospitalizace.rozdil.stredni : 0} withoutZero='1'  /></small>
                                </div>
                                <div className="item-value-small">
                                    {crHospitalizace.lehky}
                                    <br />
                                    <small><ColorValue value={crHospitalizace.rozdil ? crHospitalizace.rozdil.lehky : 0} withoutZero='1'  /></small>
                                </div>
                                <div className="item-value-small">
                                    {crHospitalizace.bezpriznaku}
                                    <br />
                                    <small><ColorValue value={crHospitalizace.rozdil ? crHospitalizace.rozdil.bezpriznaku : 0} withoutZero='1'  /></small>
                                </div>
                            </div>





                                            </div>
                        <div className="info-block-last">
                            <div className="item">
                                <div className="item-label">R:</div>
                                <div className="item-value item-big-center">{cr.R ? cr.R.toFixed(4) : 0}</div>
                            </div>
                            <div className="item">
                                <div className="item-label">Nové případy za týden (na 100 tisíc):</div>
                                <div className="item-value item-big-center">
                                    {incidence7} &nbsp;&nbsp;
                                    {cr.rozdil && cr.rozdil['incidence_100'] > 0 && (<strong className="item-red">↗</strong>)}
                                    {cr.rozdil && cr.rozdil['incidence_100'] < 0 && (<strong className="item-green">↘</strong>)}
                                </div>
                                <div className={incidence7color}></div>
                            </div>
                        </div>
                    </div>
                    <div className="action">
                        <div className="action-item" onClick={toggleHistory} >Historie</div>
                        <div className="action-item" >Grafy</div>
                        <div className="action-item" onClick={toggleHospitalizace} >Hospitalizace</div>
                        <div className="action-item" >Očkování</div>
                        <div className="action-item" >Kraje</div>
                    </div>
                    {isHistoryOpened && ( <History historie={cr.historie} /> )}
                    {isHospitalizaceOpened && ( <HistoryHospitalizace historie={crHospitalizace.historie} /> )}
                </div>
                )}
            </div>
        </div>
    );
}

export default Cr;