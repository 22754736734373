import React, { useEffect, useState } from 'react';
import './History.css';
import Moment from 'moment';
import 'moment/locale/cs'
import ColorValue from "./ColorValue";


function HistoryHospitalizace(props) {

    const [isOpened, setIsOpened] = useState(false);

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
    }

    Moment.locale('cs');

    console.log(props)

    const numberOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 };
    const numberFormat = new Intl.NumberFormat(navigator.language, numberOptions);

    return (
        <div className="HistoryHospitalizace">

            <span className="block-in-btn">za poslední 30 dní</span>
            <span className="block-in-btn">vše</span>

            <div className="data">
                <div className="data-header">
                    <div className="data-col-date">Datum</div>

                    <div className="data-col">Počet</div>
                    <div className="data-colb">denní rozdíl</div>

                    <div className="data-col">Těžký</div>
                    <div className="data-colb">denní rozdíl</div>
                    <div className="data-col">Střední</div>
                    <div className="data-colb">denní rozdíl</div>
                    <div className="data-col">Lehký</div>
                    <div className="data-colb">denní rozdíl</div>
                    <div className="data-col">Bez příznaku</div>
                    <div className="data-colb">denní rozdíl</div>

                </div>

                {Object.keys(props.historie).map((key, index)=>(
                <div className={index > 30 ? 'data-items data-items30' : 'data-items'}>
                    <div className="data-col-date">{Moment(key).format('ddd D.M.Y')  }</div>

                    <div className="data-col">{numberFormat.format(props.historie[key].pocet)}</div>
                    <div className="data-colb"><ColorValue value={props.historie[key].rozdil.pocet} withoutZero='1'  /></div>

                    <div className="data-col">{numberFormat.format(props.historie[key].tezky)}</div>
                    <div className="data-colb"><ColorValue value={props.historie[key].rozdil.tezky} withoutZero='1'  /></div>
                    <div className="data-col">{numberFormat.format(props.historie[key].stredni)}</div>
                    <div className="data-colb"><ColorValue value={props.historie[key].rozdil.stredni} withoutZero='1'  /></div>
                    <div className="data-col">{numberFormat.format(props.historie[key].lehky)}</div>
                    <div className="data-colb"><ColorValue value={props.historie[key].rozdil.lehky} withoutZero='1'  /></div>
                    <div className="data-col">{numberFormat.format(props.historie[key].bezpriznaku)}</div>
                    <div className="data-colb"><ColorValue value={props.historie[key].rozdil.bezpriznaku} withoutZero='1'  /></div>

                </div>

                ))}
            </div>
        </div>
    );
}

export default HistoryHospitalizace;