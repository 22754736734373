import {useEffect, useState} from "react"
import api from "../api"

export default function useORP(id) {

  const [orp, setORP] = useState([])

  let params = ""

  if ( id ) {
    params = `?id=${id}`
  }

  useEffect(() => {
    api.get(`/orp${params}`)
        .then(({data}) => setORP(data))
  }, [])

  return orp
}
